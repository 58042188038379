<template>
    <v-card
        rounded
        elevated
        class="card-transparent contact-form flex-column"
    >
        <v-card-title class="text-h5 testestest">
            Contact now
        </v-card-title>
        <v-card-text class="text-regular">
            <span>Let’s make finding your dream home easy!</span><br><br>
            <span>Fill out the contact form and I will get back to you as soon as possible. I’m ready when you are!</span>
        </v-card-text>
        <v-card-text>
            <v-form
                class="contact-form__form"
                @submit.prevent="contact"
            >
                <v-container
                    fluid
                    class="pa-0"
                >
                    <v-row dense>
                        <v-col hidden>
                            <v-text-field
                                v-model="contactFormName"
                                name="formName"
                                :value="props.formName"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="contactName"
                                name="name"
                                variant="outlined"
                                density="compact"
                                :error-messages="nameErrors"
                                label="Name"
                                placeholder="Your Name"
                                autocomplete="off"
                                maxlength="64"
                                required
                                :rules="[
                                    (v) => !!v || 'Name is required',
                                    (v) => !/\d/.test(v) || `Provided name contains numbers`,
                                ]"
                                clearable
                                counter
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="contactEmail"
                                name="email"
                                clearable
                                variant="outlined"
                                autocomplete="off"
                                density="compact"
                                label="E-mail"
                                :error-messages="emailErrors"
                                placeholder="user@email.example"
                                type="email"
                                :rules="[
                                    (v) => /^.+\@.+(?:\.).+/.test(v.replace(/\s/, '')) || 'Provided E-mail is not valid',
                                ]"
                                required
                                counter
                                maxlength="64"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="contactPhone"
                                name="phone"
                                clearable
                                variant="outlined"
                                autocomplete="off"
                                density="compact"
                                label="Phone"
                                :hide-details="!phoneErrors"
                                :error-messages="phoneErrors"
                                placeholder="234-567-8900"
                                :rules="[
                                    (v) => !/[a-z]/.test(v.toLowerCase()) || 'Phone number must not contain letters',
                                    (v) => /^(?:\+?\d\-?)?(?:\d{3}-?){2}(?:\d{2}-?){2}/.test(v) || 'Provided number has incorrect format',
                                ]"
                                required
                                type="phone"
                                maxlength="16"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                name="message"
                                density="compact"
                                label="Message"
                                variant="outlined"
                                max-length="300"
                                clearable
                                :rules="[
                                    (v) => !/^\s+$/.test(v) || 'Message must contain information',
                                ]"
                            />
                        </v-col>
                    </v-row>
                    <v-btn
                        class="w-100"
                        color="primary"
                        type="submit"
                        min-height="44"
                        text="Contact now"
                    />
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
const props = defineProps<{ formName: string; }>()

const nameErrors = ref<string[] | string>([])
const emailErrors = ref<string[] | string>([])
const phoneErrors = ref<string[] | string>([])

const contactFormName = ref('')
const contactName = ref('')
const contactEmail = ref('')
const contactPhone = ref('')

const isFormValid
    = computed(() =>
        contactName.value
        && contactEmail.value
        && contactPhone.value
        && contactFormName.value
    )

const isFormSent = ref(false)

async function contact(e: SubmitEvent) {
    const formData = new FormData(e.target as HTMLFormElement)
    const data = {} as Record<string, any>
    for (const [key, value] of formData){
        data[key] = value
    }
    data.pageUrl = window.location.href

    const { data: contactResponse, execute } = await useFetch<number>('/api/contact', {
        lazy: true,
        method: 'post',
        body: data,
        immediate: false,
    })
    await execute()
    if(!contactResponse) {
        return
    }
    isFormSent.value = true
    const router = useRouter()
    router.push('/thank-you')
}
</script>

<style lang="scss">
.contact-form__form .v-label {
    opacity: 100% !important;
}

.bg-black .contact-form__form .v-counter {
    color: rgb(var(--v-theme-on-black));
    min-width: 40px !important;
    flex-wrap: nowrap;
}

.contact-form__form .v-textarea textarea {
    max-height: 100px !important;
}
</style>
